.search-bar {
  position: relative;
  input {
    border: solid rgb(226, 226, 226) 2px;
    border-radius: 30px;
    height: 40px;
    width: 30vw;
    margin: 10px 5px 10px 0;
    padding-left: 20px;
    font-size: 16px;

    &:focus {
      outline: none;
    }
  }
  button {
    bottom: 23px;
    right: 10px;
    position: absolute;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}

@media all and (max-width: 780px) {
  .search-bar {
    input {
      font-size: 11px;
      height: 25px;
      width: 50vw;
    }
    button {
      bottom: 18px;
    }
  }
}
