.deck-item {
  width: 100%;
  min-height: 60px;
  border: 2px solid rgb(206, 204, 204);
  border-radius: 10px 10px 0 0;
  display: flex;
  margin: 5px 0px 5px 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  font-size: 14px;

  .hidden {
    display: none;
  }

  .expanded-info {
    width: 100%;
    background-color: rgb(32, 32, 32);
    color: white;

    .deck-list-container {
      width: 100%;
      padding: 0 15px 0 15px;
      margin: 5px;

      .deck-name {
        display: none;
      }
      .deck-list {
        border: none;
      }
    }
  }

  h3,
  h2 {
    margin: 0;
  }

  .colors {
    display: flex;
    flex-direction: row;
    align-self: flex-start;

    .color {
      margin: 2px;
    }

    img {
      height: 15px;
      width: 15px;
    }
  }
}

.lower-deck-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .expanded-info-button {
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-top: 5px;
    background-color: rgb(122, 122, 122);
    color: white;
    padding: 3px 0 5px 0;

    > * {
      pointer-events: none;
    }

    &.clicked {
      background-color: rgb(122, 122, 122);
    }
  }

  .unexpanded-info {
    position: relative;
    margin: 10px 10px 2px 10px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.842);
    border-radius: 5px;

    .col {
      flex: 1;
    }

    .deck-title-information {
      display: flex;
      flex-direction: column;
      text-align: left;

      h2 {
        font-size: 16px;
        text-transform: uppercase;
      }
      h3 {
        font-size: 14px;
      }
    }

    .record {
      font-size: 22px;
      text-align: center;
      align-self: center;

      .wl {
        color: gray;
        font-size: 20px;
      }
    }

    .seven-wins-icon {
      color: rgb(211, 180, 3);
    }

    .rare-mythic-cards {
      display: flex;
      flex-direction: column;
      text-align: right;
      justify-content: space-between;
    }
  }
}
