@mixin rarity($rgb, $rgba, $class) {
  #{$class} {
    position: relative;
    height: 25%;
    font-size: 12px;
    border-radius: 25px;
    padding-left: 15px;
    padding-right: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: $rgba;
    color: $rgb;
    display: flex;
    flex-direction: row;
    font-weight: bold;

    .details-rarity-pip {
      position: absolute;
      right: 12px;
      top: 7px;
      height: 10px;
      width: 10px;
      background-color: $rgb;
      border-radius: 50%;
    }
  }
}

.card-details-page {
  display: flex;
  justify-content: center;
  padding: 50px;

  .card-details-container {
    display: flex;
    flex-direction: row;
    height: 400px;

    .card-image {
      img {
        max-height: 100%;
        padding-right: 15px;
      }
    }
    .card-information {
      padding: 15px;

      .details-card-colors {
        text-transform: uppercase;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;

        @include rarity(rgb(255, 0, 0), rgba(255, 0, 0, 0.17), ".mythic");
        @include rarity(rgb(219, 187, 4), rgba(255, 215, 0, 0.17), ".rare");
        @include rarity(
          rgb(179, 178, 178),
          rgba(192, 192, 192, 0.17),
          ".uncommon"
        );
        @include rarity(rgb(0, 0, 0), rgba(0, 0, 0, 0.17), ".common");

        .color-name {
          img {
            padding-top: 2px;
            height: 18px;
          }
        }
      }

      h1 {
        margin: 0;
      }
      .all-information {
        display: flex;
        flex-direction: row;
        > * {
          margin: 10px;
        }
        .row {
          width: 230px;
        }
        .stats-table {
          .label {
            background-color: rgb(57, 68, 131);
          }
          .data {
            background-color: rgb(57, 68, 131);
          }
          .icon {
            background-color: rgb(103, 112, 167);
          }
        }
        .rankings-table {
          .row {
            width: 300px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 780px) {
  .card-details-page {
    padding: 5px;

    .card-details-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .card-image {
        max-width: 80%;
        height: 100%;
        img {
          padding-right: 15px;
        }
      }
      .card-information {
        display: flex;
        flex-direction: column;
        align-items: center;
        .all-information {
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}
