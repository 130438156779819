.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 180px;
  height: 35px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 10px 20px -2px rgba(0, 0, 0, 0.33);
  margin: 10px 0;

  .icon {
    width: 20%;
    background-color: rgb(155, 155, 155);
    display: flex;
    align-items: center;
    color: lightgray;
    justify-content: center;
  }
  .label {
    width: 60%;
    background-color: rgb(122, 122, 122);
    display: flex;
    align-items: center;
    color: white;
    font-size: 12px;
    justify-content: center;
    text-transform: capitalize;
  }
  .data {
    width: 20%;
    background-color: rgb(122, 122, 122);
    display: flex;
    align-items: center;
    color: white;
    font-size: 14px;
    justify-content: center;
  }
}
