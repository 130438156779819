.profile-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;

  .profile-details-container {
    display: flex;
    flex-direction: row;
    width: 55vw;

    .sidebar-container {
      display: flex;
      flex-direction: column;
      width: 30%;

      .favorite-cards-box,
      .colors-data-box,
      .factions-data-box {
        background-color: white;
        padding: 5px;
        margin-bottom: 10px;
      }

      .colors-data-box {
        border-radius: 10px;
        border: 2px solid lightgray;

        .color-title {
          h3 {
            color: rgb(86, 86, 146);
            font-size: 14px;
            margin: 5px;
          }
        }
        .color-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 7px;
          border-top: 1px solid lightgray;

          img {
            width: 20px;
            height: 20px;
          }

          .color-info {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .color-win-pct {
              font-size: 12px;
              color: gray;
              font-weight: bold;

              .win-rate-text {
                font-weight: normal;
              }
            }

            .color-data {
              padding: 5px;
              font-size: 12px;

              .win-loss-text {
                color: gray;
              }
            }
          }
        }
      }

      .factions-data-box {
        border-radius: 10px;
        border: 2px solid lightgray;

        .faction-title {
          h3 {
            color: rgb(86, 86, 146);
            font-size: 14px;
            margin: 5px;
          }
        }
        .faction-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 3px;
          border-top: 1px solid lightgray;

          .faction-name {
            width: 40px;
            font-size: 10px;
            display: flex;
            flex-direction: column;

            .faction-colors {
              img {
                width: 15px;
                height: 15px;
              }
            }
          }

          .faction-info {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .faction-win-pct {
              font-size: 12px;
              color: gray;
              font-weight: bold;

              .win-rate-text {
                font-weight: normal;
              }
            }

            .faction-data {
              padding: 5px;
              font-size: 12px;

              .win-loss-text {
                color: gray;
              }
            }
          }
        }
      }

      .favorite-cards-box {
        border-radius: 10px;
        border: 2px solid lightgray;

        .favorite-title {
          h3 {
            color: rgb(86, 86, 146);
            font-size: 14px;
            margin: 5px;
          }
        }

        .favorite-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 7px;
          border-top: 1px solid lightgray;

          .favorite-info {
            width: 100%;
            padding-left: 10px;
            display: flex;
            flex-direction: column;

            .top-row {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              span {
                margin: 0;
                color: gray;
                font-size: 12px;
                font-weight: bold;
              }

              a:link,
              a:visited {
                text-decoration: none;
                color: black;
              }
              a:hover {
                color: gray;
              }
            }

            h4 {
              margin: 0;
              font-size: 13px;
            }

            .card-data {
              span {
                font-size: 12px;
                margin: 0;
              }
              .win-loss-text {
                color: gray;
              }
            }
          }
        }

        img {
          height: 45px;
          width: 45px;
          border-radius: 50%;
        }
      }
    }

    .deck-container {
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 15px;

      h2 {
        margin: 2px;
      }

      .deck-box {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }
      button {
        border-radius: 5px;
        height: 40px;
        width: 150px;
        border: none;
        background-color: rgb(122, 122, 122);
        color: white;
        cursor: pointer;
        margin-top: 10px;

        &:hover {
          transition: background-color 0.2s ease-in;
          background-color: black;
          animation: pulse 0.2s;
        }
      }
    }
  }

  .player-container {
    width: 50vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // border-bottom: 3px solid rgb(90, 90, 90);
    border-radius: 15px;
    padding: 30px;

    .avatar {
      position: relative;
      width: 100px;
      height: 100px;
      overflow: hidden;
      border: 2px solid black;
      margin-right: 15px;
      border-radius: 5px;
      align-self: flex-end;

      img {
        position: absolute;
        right: -170px;
        top: -10px;
        width: 400%;
      }
    }

    .player-info-container {
      display: flex;
      flex-direction: column;
      width: 80%;

      .player-stats {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .row {
          margin: 3px 5px 3px 0;
          height: 25px;
        }
      }
    }
    .username {
      display: flex;
      flex-direction: row;
      align-items: center;

      h2 {
        margin: 0;
        padding-left: 10px;
      }
    }
  }
}

@keyframes pulse {
  0% {
    width: 150px;
    height: 40px;
  }
  50% {
    width: 154px;
    height: 43px;
  }
  100% {
    width: 150px;
    height: 40px;
  }
}

@media all and (max-width: 980px) {
  * {
    font-size: 10px;
  }
  .deck-box {
    .deck-item {
      margin: 1px;

      .expanded-info {
        .deck-list-page {
          .deck-list-container {
            padding: 2px;
            margin: 1px;
            .deck-list {
              padding: 5px;
            }
          }
        }
      }
    }
  }
  .profile-page {
    .player-container {
      width: 90vw;
      .player-info-container {
        align-items: center;
        .player-stats {
          justify-content: center;
        }
      }
      .avatar {
        display: none;
      }
      .username {
        h2 {
          font-size: 16px;
        }
      }
    }
    .profile-details-container {
      width: 100vw;
      flex-direction: column;
      align-items: center;

      .deck-container {
        width: 95%;
      }
      .sidebar-container {
        width: 70%;
        .favorite-cards-box {
          .favorite-row {
            padding: 1px;
          }
          .favorite-info {
            padding-left: 2px;
          }
          .favorite-title {
            h3 {
              font-size: 12px;
            }
          }
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .lower-deck-item {
      .unexpanded-info {
        .record {
          font-size: 14px;
          .wl {
            font-size: 14px;
          }
        }
        .rare-mythic-cards {
          display: none;
        }
        .deck-title-information {
          h2,
          h3 {
            font-size: 11px;
          }
        }
      }
    }
  }
}
