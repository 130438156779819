.header {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: white;

  .set-selection {
    margin-left: 50px;
    display: flex;
    align-items: center;
    height: 100%;
    width: 50%;
    justify-content: flex-start;

    label {
      font-size: 14px;
    }

    select {
      margin: 10px;
      border-radius: 5px;
      padding: 5px;
      color: white;
      background-color: rgb(73, 73, 73);
      border: none;

      &:focus {
        outline: none;
      }
    }
  }

  .options {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      text-decoration: none;
      color: black;

      &:hover {
        color: gray;
      }
    }

    .option {
      padding: 10px 15px;
      cursor: pointer;
    }
  }
}

@media all and (max-width: 780px) {
  .header {
    .set-selection {
      margin-left: 10px;

      label {
        font-size: 10px;
      }
    }
    .options {
      .option {
        padding: 2px 3px;
      }
    }
  }
}
