span {
  margin: 5px;
}

.deck-list-page {
  display: flex;
  justify-content: center;
  align-items: center;

  .deck-list-container {
    margin-top: 5%;
    height: 300px;
    width: 80%;

    h1 {
      padding-left: 10px;
      margin: 0;
      text-transform: uppercase;
    }

    .deck-list {
      padding: 15px;
      border-radius: 10px;
      border-top: 2px solid rgb(100, 100, 100);
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 90%;
    }
  }
}
