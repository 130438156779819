$base-color: black;
$secondary-color: white;

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-col {
  display: flex;
  flex-direction: column;
}

.homepage {
  @include flex-col;
  align-items: center;
  .hero {
    @include flex-col;
    align-items: center;
    h1 {
      font-family: "Raleway", sans-serif;
      margin: 5px;
      font-size: 50px;
      margin-bottom: 0px;
      span {
        color: rgb(221, 63, 15);
        font-family: "Raleway", sans-serif;
        margin: 0;
        text-shadow: 1px 1px 1px rgb(0, 0, 0);
      }
    }

    .subtitle {
      margin: 0;
      margin-bottom: 50px;
    }
  }
  .news-section {
    width: 100%;

    .banner {
      display: flex;
      justify-content: center;
      padding: 25px;
    }
  }

  .stats-section {
    @include flex-row;
    width: 75%;
    margin-top: 50px;
  }
  .top-players,
  .top-cards,
  .top-decks {
    width: 25%;
    margin: 5px;
  }
}

@media all and (max-width: 780px) {
  .homepage {
    .hero {
      h1 {
        font-size: 18px;
        span {
          font-size: 18px;
        }
      }
    }
    .subtitle {
      margin-bottom: 15px;
    }
    .stats-section {
      width: 95%;
      flex-wrap: wrap;
      margin-top: 15px;
    }
    img {
      width: 100vw;
    }
    .top-players,
    .top-cards,
    .top-decks {
      width: 45%;
      margin: 5px;
    }
  }
}
