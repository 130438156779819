.stats-page {
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    color: rgb(2, 2, 175);
  }

  a {
    color: black;
    text-decoration: none;

    &:hover {
      color: gray;
    }
  }
}

.table {
  width: 80vw;
  padding: 20px;
  background-color: white;
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 20px;

  input {
    border: solid rgb(226, 226, 226) 2px;
    border-radius: 30px;
    height: 30px;
    width: 300px;
    margin: 10px 5px 10px 0;
    padding-left: 15px;
    align-self: flex-start;

    &:focus {
      outline: none;
    }
  }
}

.table-header {
  display: flex;
  flex-direction: row;
  background-color: rgba(238, 238, 252, 0.76);
  color: rgb(163, 162, 162);
  border-radius: 8px;
  text-align: center;

  svg {
    padding-left: 10px;
  }

  h3 {
    width: 20%;
    font-size: 14px;
    cursor: pointer;
  }
}

.table-row {
  text-align: center;
}

.magic-card {
  display: flex;
  flex-direction: row;
  width: 100%;

  div {
    width: 20%;
    margin: 1px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media all and (max-width: 780px) {
  .stats-page {
    a {
      font-size: 8px;
    }
    .table {
      margin-top: 5px;
      input {
        width: 150px;
        height: 15px;
      }
    }
    .magic-card {
      div {
        padding: 0;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
    .table-header {
      h3 {
        font-size: 8px;
      }
    }
  }
}
